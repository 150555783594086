@media screen and (min-width: 992.98px) {
    .alignMenu{height: calc(132.5vh - 205px)}
}

@media screen and (max-width: 992.98px) {
.btnMain{min-width: 45%}
}

@media only screen and (max-width: 526px) {
    .btnMain{min-width: 100%}
}