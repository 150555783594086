@import "index";
@import "Content/_checkbox.scss";
@import "Content/_popup.scss";
@import "Content/_scrollbar.scss";
@import "Buttons/_button.scss";
@import "Content/_print.scss";
@import url('../CSS/icon_style.css');

$bt-secondary-color: #106EBE;
$body-bg-color: #F3F2F1;

body{font-size: 15px !important;font-family: "Poppins", sans-serif !important}

.btn-outline-primary{border: 1px solid #106EBE; color: #106EBE}

.btn-primary, .btn-primary:hover, .btn-outline-primary:hover{background-color: #106EBE; border: none}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {color: #fff; background-color: #106EBE; border-color: #106EBE}

.bgMainSection{background-color: #F7F8FA;}

.mainHeading{color: white; font-size: 18px; font-weight: 700}

.btn{font-size: 14px !important}

.pure-material-checkbox {line-height: 19px; margin-bottom: 0; text-transform: inherit; color: #000}

.pure-material-checkbox>span:before {position: absolute; left: 0; margin: 0; width: 18px; height: 18px; top: 0}

.CheckboxClass{width: 23px; height: 18px}

.pure-material-checkbox>span::before {background: #fff}

.pure-material-checkbox {line-height: 19px; margin-bottom: 0; text-transform: inherit; color: black}

.pure-material-checkbox span {padding-left: 25px;display: inline-block;font-weight: normal;line-height: 18px}

.pure-material-checkbox>span::before {position: absolute;left: 0; margin: 0; width: 18px; height: 18px; top: 0}

.pure-material-checkbox.without-text {width: 19px;height: 17px}

.pure-material-checkbox.checkbox-hgt>input {height: 40px !important}

.chkAlign{margin-left: 32px; margin-top: -23px}

.fw-700{font-weight: 700}

.bgBlue{background-color: #106EBE; height: 250px}

.btnMain{min-width: 180px; font-weight: 600}

.bgWghite{background-color: white}

.rules{color:#0728FF !important; text-decoration: none !important}

.btn-primary, .btn-outline-primary{min-width: 100px !important}

.justify{text-align: justify !important}

.bgHead {padding-top: 14px !important}

.activeTab{background-color: #DDEBFC}

.modal-header,
.modal-footer {
    padding: 12px 15px;
}

.modal-header {
    background: $bt-secondary-color;
}

.modal-title {
    color: #fff;
    font-weight: 500;
    font-size: 16px;
}

.close {
    color: #fff;
    opacity: 0.7;
}
/*modal*/
.modal-xl {
    max-width: 900px;
}

.modal-xxl {
    max-width: 1270px;
}

.modal_1150 {
    max-width: 1150px;
}

.modal_1040 {
    max-width: 1040px;
}

.modal_90 {
    max-width: 90%;
}

.modal_80 {
    max-width: 80%;
}

.modal_400 {
    max-width: 400px;
}

.modal_650 {
    max-width: 650px;
}

.modal-xl-921 {
    max-width: 921px !important;
}

.modal {
    align-items: center;
}

.modal-dialog {
    margin: 20px auto;
    height: auto;
}

.modal-dialog-centered {
    min-height: calc(100% - (20px * 2));
}

.check_modal {
    position: relative;
}

.check_modal a {
    position: absolute;
    left: 0;
    top: 0;
    width: 22px;
    height: 22px;
    z-index: 1;
}

.modal-medium {
    max-width: 600px;
}

body.modal-open bs-modal-backdrop.modal-backdrop.fade.show {
    z-index: 1049;
}

.common_table{background-color:#fff;float:left;width:100%;}

.table {table-layout: fixed;display: inline-table;border: 1px solid #d2d2d2;

    thead th{border:none;background-color:$bt-secondary-color;font-size:15px;padding:7px 15px;color:#fff;font-weight:400;vertical-align:middle}

    tbody td {
        color: $text-color;border-top: none;border-right: 1px solid #d2d2d2;padding: 8px 15px;Font-Size: 13px;overflow-wrap: break-word;

        .acc_no{width:70%;background:#fff;padding:5px 10px;color:$text-color;display:none;border-radius:25px;bottom:-35px;z-index:1;left:50%;margin-left:-35%;text-align:center}
    }
}

.table-striped tbody tr:nth-of-type(odd){background-color:#fff}

.table-striped tbody tr:nth-of-type(even){background-color:$table-pattern}

.table-hover tbody tr:hover{background:$table-over-color}

.table-hover tbody tr:hover .acc_no,
.table-hover tbody tr:hover .desired_inner_modal {display: block}

// SAVE, CANCEL & DELETE Buttons width fixed.
.bottom_button,
.bottom_button_section,
.bottom_button_section_fixed,
.bottom_button_section_pros,
.top_title_section,
.modal-footer,
.sameWidthBtn,
.buttonDiv,
.tab_butt_sec,
.rgt_butt,
.top_title_withbutton,
.rightBtnSection {
    .btn {
        min-width: 100px;
    }
}

.btn {
    text-transform: capitalize;
    font-size: 14px;
    padding: .563rem .75rem;
}

.btn-group-sm>.btn,
.btn-sm {
    text-transform: capitalize;
    font-size: 11px;
    padding: .25rem .5rem;
}

.modal.fade.show:last-child {
    z-index: 1051;
    background: rgba(0, 0, 0, 0.5);
}

.modal.fade.show {
    background: rgba(0, 0, 0, 0.5);
}

.modal-backdrop {display: none}

.e-grid .e-headercell, .e-grid .e-detailheadercell {background-color: $bt-secondary-color !important}

.bottom_button {display: flex;justify-content: flex-end;padding-bottom: 15px; width: 100%;}
.bottom_button_section_fixed { width: 90%; position: fixed; left: 5%; bottom: 15px;display: flex;justify-content: flex-end;}
.bottom_button_section_pros {position: absolute; bottom: 15px; width: 100%;  left: 0;padding: 0 15px;text-align: right;}

.mainContainer {max-width: 98% !important;margin-left: 0.75rem !important;margin-right: 0.75rem !important;padding-left: 10px;padding-right: 10px}

.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {color: white !important;outline: none !important}

.form-control {font-size: 15px !important;}

.modal-body {max-height: 60vh;overflow: auto;padding: 10px 15px}

.modal-unit label{text-transform:capitalize}

label{font-weight:400;line-height:normal}

.without_margin_label label {margin: 0 0 5px 0;}

.form_left label,.form_right label {width: 120px;float: left; margin: 10px 0 0;font-weight: bold;}

label {margin: 0 0 5px 0}
